@import 'ui/variables.module.scss';

.inputRequest {
  align-items: center;
  margin-bottom: 16px;

  .delete {
    width: 16px;
    margin-left: 8px;
    cursor: pointer;
    color: $error;
  }
}
