$hiding-time: 1000ms;
$text-color: #eff2f5;

.container {
  position: absolute;
  inset: 0;
  background-color: rgb(0, 12, 23);
  color: $text-color;
  z-index: 9999;
  padding-top: 25vh;
  font-weight: 500;
  font-size: 16px;
  overflow: hidden;
  transition: color 0.5s;
  opacity: 1;

  & > * {
    z-index: 1;
  }

  &.hide {
    animation: vanish 400ms ease-in forwards;
    animation-delay: $hiding-time - 200ms;
    color: unset;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;

    top: 0;
    bottom: 0;
    width: 100vh;
    height: 100vh;

    background-color: $text-color;
    border-radius: 50%;
    transform: scale(0.0001);
    transform-origin: 50% 50%;
  }
}

.hide.container:before {
  animation: grow $hiding-time ease-out forwards;
}

.logo {
  height: 256px;
  width: 256px;

  .segment {
    stroke-width: 6;
    animation: dash 4s ease-in infinite alternate;
    animation-delay: 200ms;
    animation-play-state: paused;
  }

  &.animated > .segment {
    animation-play-state: running;
  }

  .spectral {
    font-size: 70px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: 700;
    letter-spacing: 0.15em;
  }
}

@keyframes vanish {
  to {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes grow {
  to {
    transform: scale(8);
  }
}

@keyframes dash {
  from {
    stroke-dasharray: 200;
  }
  to {
    stroke-dasharray: 800;
  }
}
