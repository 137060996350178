@import 'ui/variables.module.scss';

$nodeWarningColor: #faad14;
$nodeWarningBorderColor: #faad14;
$nodeWarningBgndColor: #fffbe6;

.step {
  margin-bottom: 16px;
}

.icon {
  margin-right: 16px;
  cursor: pointer;
}

.active {
  border: 1px solid $blue;
}

.disabled {
  background-color: $lightGrey;
}

.warning {
  color: $nodeWarningColor;
  background-color: $nodeWarningBgndColor;
  border-color: $nodeWarningBgndColor;

  :global(.selected) & {
    border-color: $nodeWarningBorderColor;
    font-weight: bold;
  }

  :global(.ant-typography) {
    color: $nodeWarningColor;
  }

  svg path {
    fill: #faad14;
  }
}
