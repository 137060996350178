@import 'ui/variables.module.scss';

.separator {
  display: block;
  width: 100%;
  height: 1px;
}

.light {
  background-color: $lightGrey;
}

.dark {
  background-color: $grey;
}
