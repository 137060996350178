.customLabelInput {
    border: none lightgrey;

    &:hover {
        border: 1px lightgrey solid ;
    }

        &:focus {
            border: 1px solid;
        }
}