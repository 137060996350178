.input.input {
  width: 256px;
}

.largeSelect {
  width: 512px;
}

.mediaButton {
  width: 70px;
  height: 70px;
  content: 'center';
}
