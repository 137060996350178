.collapsePanel {
  .ant-collapse-header-text {
    font-weight: 700;
  }

  :global(.ant-collapse-item) {
    border-bottom: 1px solid #e2e5e9;
    padding: 24px 0;
  }

  :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
    flex-direction: row-reverse;
    padding: 0 0 12px;
  }

  :global(.ant-collapse-content-box) {
    padding-left: 0;
    padding-right: 0;
  }

  :global(.ant-collapse) {
    margin-top: -16px;
  }
}
