@import "ui/variables.module.scss";

body {
    height: 100%;
}

body {
    margin: 0;
}

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.logo {
    padding-bottom:1em;
    width: 100%;
}

.text {
    color:white;
    font-size: 1.2em;
}