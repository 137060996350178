@import "ui/variables.module.scss";

.taskIcon {
  margin-right: 16px;
}

.taskInput:focus {
  background-color: $lightGrey;
  width: 200px;
  border-radius: 30px;
}

.taskInput {
  background-color: $lightGrey;
  width: 200px;
  border-radius: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
