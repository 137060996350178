.layout {
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.sidebar {
  height: 100%;
  color: white;

  .top {
    width: 100%;
    padding: 24px 12px 0 12px;

    .logo {
      padding: 0 24px;
    }

    .logoCollapsed {
      padding: 0 8px;
    }
  }
  .middle {
    overflow: auto;
    height: 0;
  }
  .bottom {
    width: 100%;
    padding: 0 12px 24px 12px;
  }
}

.content {
  overflow: auto;
  height: 100%;
  flex: 1 1 auto;
  padding-top: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;
}

.divider {
  border-color: rgba(255, 255, 255, 0.5);
}
