@import 'ui/variables.module.scss';

.warning {
  margin-bottom: 20px;
}

.condition {
  flex-wrap: wrap;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  .conditionElement {
    &:first-child {
      display: flex;
      align-items: center;

      span {
        margin-right: 16px;
      }
    }

    &:last-child {
      margin-left: 29px;
    }

    .select {
      width: calc(100% - 56px);
    }

    .delete {
      width: 16px;
      margin-left: 8px;
      cursor: pointer;
      color: $error;
    }

    .navigation {
      width: 16px;
      margin-left: 8px;
      cursor: pointer;
    }
  }
}
