@import 'ui/variables.module.scss';

.gearsPanel {
  width: 300px;
  padding: 10px;
  border-left: solid 4px $lightGrey;
}

.gearCard {
  width: 296px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 4px;
}

.gearCard:hover {
  background-color: $lightGrey;
  border-radius: 8px;

  .gearCardDeleteButton {
    display: block;
  }
}

.gearCardElement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 35px;
}

.gearCardLabelContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 9px;
}

.gearCardDeleteButton {
  color: rgba(230, 0, 0, 0.85);
  border: solid 1px rgba(230, 0, 0, 0.85);
  padding: 0 3px;
  display: none;
}
