.loader {
  display: flex;
  justify-content: center;
}

.title {
  font-weight: bold;
}

.space {
  margin-top: 32px;
}

.checkbox {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}
