$light: #ffffff;
$lightBlue: #0cb4c9;
$lightGrey: #f4f5f6;
$grey: #00000073;
$blue: #1890ff;
$darkBlue: #40a9ff;
$microsoftBlue: #2185d0;
$red: #FF0000;

$error: #ff7875;

$border-color: #d9d9d9;
