$nodeBgndColor: #f0f5ff;
$nodeColor: #2f54eb;
$nodeBorderColor: #adc6ff;
$nodeWarningBgndColor: #fffbe6;
$nodeWarningColor: #faad14;
$nodeWarningBorderColor: #faad14;
$disabledColor: #adb5bd;
$taskBorderColor: #9747ff;
$taskColor: #9747ff;
$taskBgndColor: rgba(239, 227, 255, 0.2);
$taskWarningBgndColor: rgba(255, 251, 230, 0.4);

%baseCustomNode {
  position: relative;
  padding: 12px 8px;
  border-radius: 8px;
  border: 1px solid $nodeBorderColor;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.customStep {
  @extend %baseCustomNode;
  background-color: $nodeBgndColor;
  min-width: 400px;
  max-width: 400px;
  min-height: 110px;
  text-align: center;
  color: $nodeColor;

  :global(.isIntersecting) & {
    border-width: 2px;
    animation: wiggle 250ms infinite;
  }

  :global(.selected) & {
    border-color: $nodeColor;
    font-weight: 900;
  }

  :global(.ant-typography) {
    color: $nodeColor;
  }

  &.hasWarning {
    color: $nodeWarningColor;
    background-color: $nodeWarningBgndColor;
    border-color: $nodeWarningBgndColor;

    :global(.selected) & {
      border-color: $nodeWarningBorderColor;
      font-weight: bold;
    }

    :global(.ant-typography) {
      color: $nodeWarningColor;
    }

    svg path {
      fill: #faad14;
    }
  }
}

.customTask {
  @extend %baseCustomNode;
  background-color: transparent;
  min-height: 140px;
  height: 100%;
  text-align: left;
  border: 1px dashed $taskBorderColor;

  :global(.selected) & {
    border-style: solid;
  }

  :global(.selected) &,
  &:hover {
    background-color: $taskBgndColor;
  }

  :global(.isIntersecting) & {
    border-width: 4px;
  }

  &.hasWarning {
    background-color: $taskWarningBgndColor;
    border: 1px dashed $nodeWarningColor;

    :global(.selected) & {
        border-style: solid;
      }

    svg path {
        fill: #faad14;
      }
  }
}

:global(#troot, #sroot) {
  visibility: hidden;
  display: none;
}

.customTaskWrapper {
  height: 100%;
}

.customTaskLabel {
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  color: $taskColor;
  display: flex;
  align-items: flex-start;

  input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: none;
    border: 0;
    border-bottom-style: none;
    border-bottom-width: 0px;
    color: $taskColor;
    padding: 0 6px;
    border-radius: 0;
    border-bottom: 1px solid transparent;

    &:focus,
    &:hover {
      border-color: $taskBorderColor;
    }
  }
}

.customCtaGroup {
  position: absolute;
  align-items: center;
  justify-content: center;
  padding: 4px 1rem;
  width: 100%;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 100ms linear, transform 150ms ease-in-out;

  &.isVisible {
    opacity: 1;
    transform: translateY(0);
  }
}

.customCtaButton {
  border-color: $nodeColor;
  width: 100%;
  min-width: 18px;
  height: 100%;
  border-width: 1px;
  background-color: transparent;

  &:hover,
  &:focus {
    background-color: $nodeColor;
    border-color: $nodeColor;

    svg path {
      fill: white;
    }
  }

  &[disabled] {
    color: $disabledColor;

    svg path {
      fill: $disabledColor;
    }

    &:hover,
    &:focus {
      color: $disabledColor;
      background-color: rgba(0, 0, 0, 0.06);

      svg path {
        fill: $disabledColor;
      }
    }
  }

  &--task {
    border-color: $taskBorderColor;

    svg path {
      fill: $taskColor;
    }

    &:hover,
    &:focus {
      background-color: $taskColor;
      border-color: $taskBorderColor;
    }
  }
}

.customStateIconsGroup {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &Bottom {
    bottom: 8px;
  }
  &Top {
    top: 8px;
  }
}

.customStateIcon {
  margin-right: 8px;
  font-size: 18px
}

.customCtaButtonIcon {
  color: $nodeColor;
  font-size: 12px;
}

.customNodeHandler {
  border: 0;
  background: none;
  z-index: 0;
}

.isLastChild {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -26px;
  top: 6px;
  height: 26px;
  width: 26px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #85a5ff;
  color: white;
  font-size: 10px;
  z-index: -1;

  .hasWarning & {
    color: $nodeWarningColor;
    background-color: $nodeWarningBgndColor;
  }
}

:global(.react-flow__handle.react-flow__handle-left) {
  left: 0;
}
:global(.react-flow__handle.react-flow__handle-right) {
  right: 0;
}

.customHandle {
  background-color: #85a5ff;
  height: 10%;
  width: 5%;
}
