@import 'ui/variables.module.scss';

.group {
  width: 100%;

  .label {
    display: inline-block;
    margin-bottom: 8px;
    font-weight: 700;
  }

  .legend {
    font-size: 12px;
    color: $grey;
  }

  .error {
    display: inline-block;
    height: 14px;
    width: 100%;
    text-align: right;
    color: $error;
    font-size: 14px;
    line-height: 1;
  }
}
