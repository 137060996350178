@import 'ui/variables.module.scss';

.inputResponse {
  margin-left: -25px;
  margin-right: -25px;

  .block {
    margin-bottom: 8px;
    margin-top: 8px;
    padding-left: 25px;
    padding-right: 25px;

    .information {
      color: $grey;
    }
  }
}
