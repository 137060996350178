.builderControlsBtnGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
}

.builderControlBtn {
  height: 40px;
  width: 40px;
  color: rgba(0, 0, 0, 0.45);

  &Left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &Right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.builderControlZoomIndicator {
  background-color: white;
  border-top: 1px solid rgb(217, 217, 217);
  border-bottom: 1px solid rgb(217, 217, 217);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  padding: 8px 14px;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;
}
