@import 'ui/global.module.scss';

.navigationContainer {
  max-width: 255px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.navigation {
  max-width: 120px;
  white-space: nowrap;
  display: inline-block;
}

.edition {
  padding: 0;
  min-height: 90dvh;

  :global(.ant-tabs-nav) {
    margin-bottom: 24px;
  }
}
