@import 'ui/variables.module.scss';

.container {
  border-radius: 4px;
  position: relative;

  .loader {
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px 16px;
  }

  .color {
    width: 10px;
    height: 20px;
    margin-right: 10px;
    position: relative;
    top: 6px;
  }

  .menu {
    border-radius: 4px 4px 0 0;
    border: 1px solid $border-color;
    border-bottom: 1px solid transparent;
    margin-bottom: -1px;
    padding: 6px 12px;
    background-color: #fafafa;

    .divider {
      border-color: #00000030;
      height: 1.2em;
    }
  }

  .editor {
    border-radius: 4px;
    background-color: #f9f9f9;
    transition: all 0.3s;

    &.editable {
      height: 160px;
      overflow: auto;
      background-color: unset;
      border-radius: 0 0 4px 4px;
      border: 1px solid $border-color;

      &:hover {
        // styles copied from ant
        border-color: $darkBlue;
      }

      &:focus-within {
        // styles copied from ant
        border-color: $darkBlue;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }
    }

    &.loading {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.activeButton {
  color: $blue;

  &:hover {
    color: $blue;
  }
}

// https://www.tiptap.dev/guide/styling
.richTextEditor {
  border: none;
  outline: none;
  width: 100%;
  min-height: 100%;
  padding: 12px 16px;
  box-sizing: border-box;

  p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 0.2em;
  }

  ul,
  ol {
    margin: 0;
    margin-bottom: 0.4em;
  }

  h1 {
    font-size: 22px;
    margin: 0;
    line-height: 1.1;
    margin-bottom: 0.6em;
  }
  h2 {
    font-size: 18px;
    margin: 0;
    line-height: 1.1;
    margin-bottom: 0.6em;
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .ProseMirror p.is-empty::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
