@import 'ui/variables.module.scss';

.thumbnail,
.folder,
.preview {
  height: 70px;
  width: 70px;
  object-fit: contain;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.detail {
  object-fit: contain;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 300px;
}
