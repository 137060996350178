@import "ui/variables.module.scss";

.highlight {
  color: $blue;
}

.title {
  color: $light;
}

.error {
  color: $error;
}
