@import "ui/variables.module.scss";

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .card {
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
      0 2px 10px 0 rgba(34, 36, 38, 0.15);
    max-width: 370px;

    .imageBlock {
      background-color: $lightBlue;
      border-radius: 5px;
      position: relative;
      margin-top: -70px;
      margin-bottom: 24px;
      padding: 15px;
      box-shadow: rgba(0, 188, 212, 0.28) 0px 12px 20px -10px,
        rgba(0, 0, 0, 0.12) 0px 4px 20px 0px,
        rgba(0, 188, 212, 0.2) 0px 7px 8px -5px;
    }

    button {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      outline: none;
      cursor: pointer;
      background: none;
      border: none;
      box-shadow: 0 0 0 1px $microsoftBlue inset;
      padding: 0.78571429em 1.5em 0.78571429em;
      border-radius: 0.28571429rem;
      color: $microsoftBlue;

      svg {
        height: 20px;
        margin-right: 12px;
      }
    }
  }
}
