@import 'ui/variables.module.scss';
@import 'ui/global.module.scss';

.modalMediaContent {
  // arbitrary values to have the modal not taking all the screen but still be tall enough in all case
  min-height: 50vh;
  overflow: auto;
}

.active {
  border: 1px solid $blue;
}

.meta {
  display: contents;
}
