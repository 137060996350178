@import "ui/variables.module.scss";

.select {
  width: 100%;
}

.userMenu {
  padding: 16px 16px 8px;
  color: rgba(255, 255, 255, 0.65);

  .username {
    text-transform: capitalize;
    font-weight: 600;
    color: $blue;
  }

  .tenantSelect {
    width: 80%;
  }

  .languageSelect {
    margin-left: 4px;
    width: calc(38% - 4px);
  }

  .singleTenant {
    font-weight: 600;
    color: $light;
    width: 100%;
  }

}

.navigationMenu {
  overflow: auto;

  .zoneSelection {
    padding: 0 16px 8px 24px;
  }

  .homeIcon {
    color: #fff;
  }
}
